.main-menu {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-content {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
}

.menu-title {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 1rem;
}

.menu-subtitle {
    font-size: 1.5rem;
    color: #ddd;
    margin-bottom: 2rem;
}

.menu-list {
    list-style-type: none;
    padding: 0;
}

.menu-item {
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #4caf50;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.menu-item:hover {
    background-color: #45a049;
}

