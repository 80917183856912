.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.h2{
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: white;
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}