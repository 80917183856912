/* Add these styles to your existing global.css file */

.screen-title {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 1.5rem;
}

.profession-list,
.month-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1.5rem;
}

.profession-item,
.month-item {
    background-color: #4caf50;
    color: #fff;
    padding: 0.8rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profession-item:hover,
.month-item:hover,
.profession-item.selected,
.month-item.selected {
    background-color: #45a049;
}

.profession-item.info {
    background-color: #2196f3;
}

.profession-item.info:hover {
    background-color: #1e88e5;
}

.selection-info {
    color: #fff;
    margin-bottom: 1rem;
}

.input-label {
    display: block;
    color: #fff;
    margin-bottom: 1rem;
}

.text-input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.3rem;
    border: none;
    border-radius: 3px;
}

.next-button,
.start-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.next-button:hover,
.start-button:hover {
    background-color: #45a049;
}

.review-list {
    color: #fff;
    list-style-type: none;
    padding: 0;
    margin-bottom: 1.5rem;
}

.review-list li {
    margin-bottom: 0.5rem;
}

.review-list ul {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
}

