.setup {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setup-content {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2rem;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
}

.setup-title {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}