.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
}

.rank {
    font-size: 20px;
    font-weight: bold;
}

.name {
    margin: 0 10px;
}

.score {
    font-size: 20px;
    font-weight: bold;
}

.h2{
    position: absolute;
    bottom: 8px;
    left: 16px;
    font-size: 40px;
    color: white;
    font-weight: bold;
}


.bottomleft{
    position: absolute;
    bottom: 8px;
    left: 16px;
    font-size: 18px;
    font-weight: bold;
}
