.home-page {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2rem;
    border-radius: 10px;
}

.title {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 2rem;
}

.start-button {
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    color: #fff;
    background-color: #4caf50;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.start-button:hover {
    background-color: #45a049;
}

